import React from "react";
import styled from "styled-components";
import RequestHeader from "./RequestHeader";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const NoneHeaderWrapper = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

const NoneWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
`;

const Request = ({
  request,
  onCancelConfirm,
  cancelLoading,
  onClose,
  decryptMessage,
  maxHeight,
  locale,
  isTherapist,
  confirmCancelTitleText,
  confirmCancelMessageText,
  confirmCancelNoText,
  confirmCancelYesText,
  detailsText,
  cancelText,
  statusText,
  statusDescriptionText,
  requestedTimeText,
  commentsText,
  emailText,
  phoneText,
  paymentText,
  issuesText,
  noneSpecifiedText,
  cashText,
  insuranceText,
  outOfNetworkText,
  noneSelectedText,
  children,
}) => {
  if (!request) {
    return (
      <Wrapper>
        <NoneHeaderWrapper>
          <RequestHeader onClose={onClose} />
        </NoneHeaderWrapper>
        <NoneWrapper>{noneSelectedText}</NoneWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <RequestHeader
        request={request}
        onCancelConfirm={onCancelConfirm}
        cancelLoading={cancelLoading}
        onClose={onClose}
        decryptMessage={decryptMessage}
        maxHeight={maxHeight}
        locale={locale}
        isTherapist={isTherapist}
        confirmCancelTitleText={confirmCancelTitleText}
        confirmCancelMessageText={confirmCancelMessageText}
        confirmCancelNoText={confirmCancelNoText}
        confirmCancelYesText={confirmCancelYesText}
        detailsText={detailsText}
        cancelText={cancelText}
        statusText={statusText}
        statusDescriptionText={statusDescriptionText}
        requestedTimeText={requestedTimeText}
        commentsText={commentsText}
        emailText={emailText}
        phoneText={phoneText}
        paymentText={paymentText}
        issuesText={issuesText}
        noneSpecifiedText={noneSpecifiedText}
        cashText={cashText}
        insuranceText={insuranceText}
        outOfNetworkText={outOfNetworkText}
      />
      {children}
    </Wrapper>
  );
};

export default Request;
