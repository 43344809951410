import React, { useState } from "react";
import styled from "styled-components";
import { formatTimeRange } from "./utils";
import { ArrowFull } from "../assets";
import { Photo } from "../elements";
import ConfirmBox from "./ConfirmBox";
import StatusBadge from "./StatusBadge";
import DetailView from "./DetailView";
import Initials from "./Initials";
import { STATUSES_ACTIVE } from "./consts";

const ProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 16px;

  @media (min-width: 900px) {
    flex-direction: row;
    gap: 7px;
    padding: 24px 16px;
  }
`;

const PhotoWrapper = styled.div`
  flex: 0;
`;

const StyledPhoto = styled(Photo)`
  width: 44px;
  height: 44px;
  @media (min-width: 900px) {
    width: 56px;
    height: 56px;
  }
`;

const NameWrapper = styled.div`
  overflow: hidden;
  flex: 1;
  text-align: center;
  margin-top: 6px;
  @media (min-width: 900px) {
    text-align: left;
    margin: 0;
  }
`;

const Name = styled.p`
  margin: 0;
  padding: 0 0 8px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateTime = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.gray};
  line-height: 0.85;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Details = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;

const StatusWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  gap: 8px;
  align-items: center;
`;

const StatusBadgeWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  flex: 0 0 auto;
  justify-content: center;
`;

const StatusDescription = styled.span`
  display: block;
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.default};
`;

const Cancel = styled.span`
  display: block;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: ${({ theme }) => theme.fontSize.normal};
  flex: 0 0 auto;
  justify-self: flex-end;
`;

const CancelGroup = styled.div`
  position: relative;
  overflow: visible;
`;

const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 16px;
  background-color: ${({ theme }) => theme.colors.light};
  svg {
    flex: 1 0 auto;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

const RequestHeader = ({
  request,
  onCancelConfirm,
  cancelLoading,
  onClose,
  decryptMessage,
  maxHeight,
  locale,
  isTherapist,
  confirmCancelTitleText,
  confirmCancelMessageText,
  confirmCancelNoText,
  confirmCancelYesText,
  detailsText,
  cancelText,
  statusText,
  statusDescriptionText,
  requestedTimeText,
  commentsText,
  emailText,
  phoneText,
  paymentText,
  issuesText,
  noneSpecifiedText,
  cashText,
  insuranceText,
  outOfNetworkText,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const getDateTime = () => {
    const date = request.startDate.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const time = formatTimeRange(
      request.startDate,
      request.stopDate,
      request.timezone,
      locale,
    );

    return `${date}, ${time}`;
  };

  if (!request) {
    return (
      <ProviderWrapper>
        <CloseButton onClick={onClose}>
          <ArrowFull direction="down" />
        </CloseButton>
      </ProviderWrapper>
    );
  }

  return (
    <div>
      <ProviderWrapper>
        <PhotoWrapper>
          {isTherapist ? (
            <Initials name={request.clientName} />
          ) : (
            <StyledPhoto
              src={request.profilePhotoUrl}
              alt={request.profileName}
            />
          )}
        </PhotoWrapper>
        <NameWrapper>
          <Name>{isTherapist ? request.clientName : request.profileName}</Name>
          <DateTime>
            {getDateTime()} -{" "}
            <Details onClick={() => setShowDetails(!showDetails)}>
              {detailsText}
            </Details>
          </DateTime>
          {showDetails && (
            <DetailView
              request={request}
              onClose={() => setShowDetails(false)}
              decryptMessage={decryptMessage}
              maxHeight={maxHeight}
              detailsText={detailsText}
              requestedTimeText={requestedTimeText}
              commentsText={commentsText}
              emailText={emailText}
              phoneText={phoneText}
              paymentText={paymentText}
              issuesText={issuesText}
              noneSpecifiedText={noneSpecifiedText}
              cashText={cashText}
              insuranceText={insuranceText}
              outOfNetworkText={outOfNetworkText}
            />
          )}
        </NameWrapper>
        <CloseButton onClick={onClose}>
          <ArrowFull direction="down" />
        </CloseButton>
      </ProviderWrapper>
      <StatusWrapper>
        <StatusBadgeWrapper>
          <StatusBadge status={request.status} statusText={statusText} />
        </StatusBadgeWrapper>
        <StatusDescription>{statusDescriptionText}</StatusDescription>
        {!isTherapist && STATUSES_ACTIVE.indexOf(request.status) >= 0 && (
          <CancelGroup>
            <Cancel onClick={() => setConfirmCancel(!confirmCancel)}>
              {cancelText}
            </Cancel>
            {confirmCancel && (
              <ConfirmBox
                title={confirmCancelTitleText}
                text={confirmCancelMessageText}
                closeText={confirmCancelNoText}
                confirmText={confirmCancelYesText}
                onClose={() => setConfirmCancel(!confirmCancel)}
                onConfirm={onCancelConfirm}
                loading={cancelLoading}
                disabled={cancelLoading}
              />
            )}
          </CancelGroup>
        )}
      </StatusWrapper>
    </div>
  );
};

export default RequestHeader;
