import React, { useState, forwardRef } from "react";
import styled, { css } from "styled-components";
import Label from "../Label";
import Eye from "../../assets/Eye";

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  border-right: 0;
  display: block;
  height: 50px;
  appearance: none;
  color: ${({ theme, disabled, error }) =>
    disabled
      ? "rgba(44, 45, 48, 0.75)"
      : error
      ? theme.colors.red
      : theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 16px;
  line-height: 1.3;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.light : theme.colors.white};
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 9px 13px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-color: ${({ theme, disabled, error }) =>
    disabled
      ? "rgba(44, 45, 48, 0.75)"
      : error
      ? theme.colors.red
      : theme.colors.border};
  border-radius: 3px;

  ::placeholder {
    color: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.borderDark};
    border-color: ${({ theme, disabled, error }) =>
      disabled
        ? "rgba(44, 45, 48, 0.75)"
        : error
        ? theme.colors.red
        : theme.colors.borderDark};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.default};
  }

  ${({ inline }) =>
    inline &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    `};

  ${({ stacked }) =>
    stacked &&
    css`
      border-radius: 0;

      &:not(:last-of-type):not(:focus) {
        border-bottom: 0;
      }

      &:first-of-type {
        border-radius: 3px 3px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 3px 3px;
      }
    `};

  ${({ adjacent }) =>
    adjacent &&
    css`
      border-radius: 0;

      &:not(:last-of-type):not(:focus) {
        border-right-width: 0;
      }

      &:first-of-type {
        border-radius: 3px 0 0 3px;
      }

      &:last-of-type {
        border-radius: 0 3px 3px 0;
      }
    `};
  ${({ icon }) =>
    icon &&
    css`
      padding-left: 30px;
      background: url("${icon}");
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: 10px center;
    `}
`;

const TogglePassword = styled.span`
  position: absolute;
  top: 35px;
  right: 10px;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.red};
`;

Input.defaultProps = {
  type: "text",
  inline: false,
  stacked: false,
  adjacent: false,
};

const TextInput = ({ label, error, type, ...rest }, ref) => {
  const [revealPassword, setRevealPassword] = useState(false);
  const toggleReveal = () => {
    setRevealPassword(!revealPassword);
  };
  const fieldType = type === "password" && revealPassword ? "text" : type;
  return (
    <Container>
      {label && <Label error={error}>{label}</Label>}
      {type === "password" && (
        <TogglePassword onClick={toggleReveal}>
          <Eye />
        </TogglePassword>
      )}
      <Input {...rest} ref={ref} error={error} type={fieldType} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default forwardRef(TextInput);
