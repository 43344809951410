import React from "react";
import styled from "styled-components";
import { H2, Button, SubmitButton } from "../elements";
import { Loading, CloseX } from "../assets";

const Text = styled.div`
  margin-bottom: 15px;
`;

const Close = styled.div`
  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
  & > ${Button} {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  padding: 15px;
  right: 0;
  width: 350px;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 8px;
  z-index: 9;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ConfirmBox = ({
  title,
  text,
  closeText,
  confirmText,
  onClose,
  onConfirm,
  loading,
}) => {
  return (
    <Wrapper>
      <Header>
        <H2>{title}</H2>
        <Close onClick={onClose}>
          <CloseX />
        </Close>
      </Header>
      <Text>{text}</Text>
      <Buttons>
        <Button onClick={onClose} variant="white" full>
          {closeText}
        </Button>
        <SubmitButton onClick={onConfirm} full>
          {loading ? <Loading /> : confirmText}
        </SubmitButton>
      </Buttons>
    </Wrapper>
  );
};

export default ConfirmBox;
