import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { swapCopyVariables } from "../utils";
import { useWindowSize } from "../hooks";
import { Photo } from "../elements";
import { DESKTOP_BREAKPOINT, FILTER_RECENT } from "./consts";
import ListHeader from "./ListHeader";
import TypeBadge from "./TypeBadge";
import Initials from "./Initials";
import { filterRequests } from "./utils";

const RequestList = styled.div`
  margin-top: 16px;
`;

const RequestItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  gap: 7px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.light};
    `}

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const PhotoWrapper = styled.div`
  flex: 0;
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  width: 18px;
  height: 18px;
`;

const DetailsWrapper = styled.div`
  overflow: hidden;
  flex: 1;
`;

const Name = styled.p`
  margin: 0;
  padding: 0 0 8px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Updated = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.gray};
  line-height: 0.85;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusWrapper = styled.div`
  flex: 0;
`;

const None = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
`;

const List = ({
  isTherapist,
  locale,
  requestHistory,
  selectedRequestId,
  requestFilter,
  onSelect,
  onFilterChange,
  // copy:
  titleText,
  recentText,
  olderText,
  noRecentText,
  noOlderText,
  updatedText,
  typeTextMap,
}) => {
  let requestHistoryFiltered = filterRequests(requestHistory, requestFilter);

  const getUpdatedAt = timestamp => {
    const d = new Date(timestamp * 1000);
    return d.toLocaleDateString(locale, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  // Auto-select first request on desktop. Assumes request history is sorted.
  const { width } = useWindowSize();
  useEffect(() => {
    const isDesktop = width >= DESKTOP_BREAKPOINT;
    if (isDesktop && requestHistory?.length && !selectedRequestId) {
      const request = requestHistory[0];
      onSelect(request.id);
    }
  }, [width, requestHistory, onSelect, selectedRequestId]);

  return (
    <>
      <ListHeader
        requestFilter={requestFilter}
        onFilterChange={onFilterChange}
        titleText={titleText}
        recentText={recentText}
        olderText={olderText}
      />
      <RequestList>
        {requestHistoryFiltered.length === 0 && (
          <None>
            {requestFilter === FILTER_RECENT ? noRecentText : noOlderText}
          </None>
        )}
        {requestHistoryFiltered.map((r, i) => (
          <RequestItem
            key={r.id}
            active={r.id === selectedRequestId}
            onClick={() => onSelect(r.id)}
          >
            <PhotoWrapper>
              {((!isTherapist && r.newActivityForClient) ||
                (isTherapist && r.newActivityForTherapist)) && <Badge />}
              {isTherapist ? (
                <Initials name={r.clientName} />
              ) : (
                <Photo src={r.profilePhotoUrl} alt={r.profileName} />
              )}
            </PhotoWrapper>
            <DetailsWrapper>
              <Name>{isTherapist ? r.clientName : r.profileName}</Name>
              <Updated>
                {swapCopyVariables(updatedText, {
                  DATE: getUpdatedAt(r.lastActivityTimestamp),
                })}
              </Updated>
            </DetailsWrapper>
            <StatusWrapper>
              <TypeBadge typeText={typeTextMap[r.type]} />
            </StatusWrapper>
          </RequestItem>
        ))}
      </RequestList>
    </>
  );
};

export default List;
