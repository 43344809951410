import { rawTimeZones } from "@vvo/tzdb";
import { FILTER_RECENT, FILTER_OLDER } from "./consts";

export const getTimezoneAbbreviation = tz => {
  const value = rawTimeZones.find(timeZone => {
    return timeZone.name === tz || timeZone.group.includes(tz);
  });

  return value ? value.abbreviation : "";
};

export const formatTimeRange = (start, stop, timezone, locale) => {
  const from = start.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const to = stop.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const tz = getTimezoneAbbreviation(timezone);
  return `${from} - ${to} ${tz}`;
};

export const formatTime = (date, timezone, locale) => {
  const t = date.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const tz = getTimezoneAbbreviation(timezone);
  return `${t} ${tz}`;
};

export const filterRequests = (requests, filter) => {
  if (!requests?.length) {
    return [];
  }
  // 90 days ago
  const ts = new Date() - 1000 * 60 * 60 * 24 * 90;
  switch (filter) {
    case FILTER_RECENT:
      return requests.filter(r => r.createdAt * 1000 >= ts);
    case FILTER_OLDER:
      return requests.filter(r => r.createdAt * 1000 < ts);
    default:
      return [];
  }
};
